import css from '../scss/_main.scss' //

import 'slick-carousel'
import 'magnific-popup'
import 'tabbedcontent/src/tabbedcontent'
import 'video.js'
import 'videojs-youtube/dist/Youtube'
import Layout from './layout'

import tabNavigation from './components/tabnavigation'
import wrapperItems from './components/wrapperitems'


/** Own scripts */

// import from './components/tabnavigation';

const Selector = {
	BODY													:	'body',
  SEARCHED_SECTION 							: '#searched',
	BANNER_CAROUSEL 							: '.js-bannerSlider',
	AGREEMENTS_CAROUSEL 					: '.js-agreementsCarousel',
	SEARCHED_CAROUSEL							: '.js-searchedCarousel',
  EVENTS_CAROUSEL 							: '.js-eventsCarousel',
  EVENTS_NEWS_CAROUSEL          : '.js-eventsNewsCarousel',
	SERVICES_CAROUSEL 						: '.js-servicesCarousel',
	GALLERY_CAROUSEL							: '.js-galleryCarousel',
  NEWS_CAROUSEL									: '.js-newsCarousel',
  TABS_INLINE_GALLERY           : '.js-tabsInlineGallery'
}

class Site {
  constructor() {
		this.mqPhoneWide = matchMedia('(max-width: 480px)')
		this.mqTablet = matchMedia('(min-width: 768px)')

    this._initCarousel()
    this._initServicesCarousel()
    this._initTabsGalleryCarousel()
    this._addEventListeners()
  }

  _initCarousel() {
		let $bannerCarousel = $(Selector.BANNER_CAROUSEL)

		if(this.mqTablet.matches){
			$bannerCarousel.find('[data-videosrc]').each((i, el) => {
				let videoSrc = $(el).attr('data-videosrc')

				if (videoSrc !== '')
					$(el).prepend(`<video muted><source src="${videoSrc}" type="video/mp4"></video>`)
			})
		}

		
		$bannerCarousel
			.on('init', (slick) => {
				$bannerCarousel.fadeIn(1000)

				$bannerCarousel.find('.slick-slide').each((i, el) => {
					if ($(el).find('video').length) {
						$(el).find('video').attr('autoplay', true).attr('loop', true);
					}
				})

				let titleSlidePrev = $bannerCarousel.find('.slick-current').prev().find('[data-slide-name]').data('slide-name')
				let titleSlideNext = $bannerCarousel.find('.slick-current').next().find('[data-slide-name]').data('slide-name')

				$bannerCarousel.find('.slick-arrow.prev > span').html(titleSlidePrev)
				$bannerCarousel.find('.slick-arrow.next > span').html(titleSlideNext)
			})
			.slick({
				infinite: true,
				autoplay: true,
				autoplaySpeed: 4000,
				prevArrow: `<div class="prev">
											<button type="button"><svg><use xlink:href="#arrow-prev"></svg></button>
											<span>&nbsp;</span>
										</div>`,
				nextArrow: `<div class="next">
											<button type="button"><svg><use xlink:href="#arrow-next"></svg></button>
											<span>&nbsp;</span>
										</div>`,
				responsive: [
					{
						breakpoint: 480,
						settings: {
							arrows: false,
							dots: true
						}
					}
				]
			})
			.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
				$bannerCarousel.find('.slick-slide video').each((i, el) => {
					$(el).get(0).pause()
				})
			})
			.on('afterChange', (event, slick, currentSlide, nextSlide) => {
				if ($('.slick-slide.slick-current').find('video').length) {
					$bannerCarousel.find('.slick-slide.slick-current video').get(0).play()
				}

				let titleSlidePrev = $bannerCarousel.find('.slick-current').prev().find('[data-slide-name]').data('slide-name')
				let titleSlideNext = $bannerCarousel.find('.slick-current').next().find('[data-slide-name]').data('slide-name')

				$bannerCarousel.find('.slick-arrow.prev > span').html(titleSlidePrev)
				$bannerCarousel.find('.slick-arrow.next > span').html(titleSlideNext)
			});

		let $agreementsCarousel = $(Selector.AGREEMENTS_CAROUSEL)
		$agreementsCarousel
			.on('init', (slick) => {
				$agreementsCarousel.fadeIn(1000)
			})
			.slick({
				infinite: true,
				centerMode: true,
				centerPadding: '50px',
				slidesToShow: 4,
				slidesToScroll: 4,
				autoplay: true,
				autoplaySpeed: 2500,
				prevArrow: `<div class="prev">
                    	<button type="button"><svg><use xlink:href="#arrow-light-left"></svg></button>
                  	</div>`,
      	nextArrow: `<div class="next">
                    	<button type="button"><svg><use xlink:href="#arrow-light-right"></svg></button>
                  	</div>`,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							centerMode: true,
							centerPadding: '40px',
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							dots: true,
							centerMode: true,
							centerPadding: '20px',
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							arrows: false,
							dots: true,
							centerMode: true,
							centerPadding: '60px',
							slidesToShow: 1
						}
					}
				]
			})
		

		let $eventsCarousel = $(Selector.EVENTS_CAROUSEL)
			

		if (!this.mqPhoneWide.matches && $eventsCarousel.find('.cardSimple').length <= 3) {
			$eventsCarousel.addClass('not-slider')
		} else {
			$eventsCarousel
				.on('init', (slick) => {
					$eventsCarousel.fadeIn(1000)
				})
				.slick({
					slidesToShow: 4,
					responsive: [
						{
							breakpoint: 768,
							settings: {	slidesToShow: 2	}
						},
						{
							breakpoint: 480,
							settings: {	slidesToShow: 1, centerMode: true	}
						}
					]
				})
		}


		$(Selector.NEWS_CAROUSEL)
			.on('init', (slick) => {
				$(Selector.SEARCHED_CAROUSEL).fadeIn(1000);
			})
			.slick({
				slidesToShow: 3,
				dots: true,
				arrows: false,
				autoplay: true,
				autoplaySpeed: 2500,
				slidesToScroll: 3,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							infinite: true,
							slidesToShow: 1,
							slidesToScroll: 1,
							centerMode: true,
							centerPadding: '20px',
						}
					}
				]
			})


		$(Selector.SEARCHED_CAROUSEL)
			.on('init', (slick) => {
				$(Selector.SEARCHED_CAROUSEL).fadeIn(1000);
			})
			.slick({
				slidesToShow: 5,
				responsive: [
					{
						breakpoint: 768,
						settings: { slidesToShow: 2 }
					},
					{
						breakpoint: 480,
						settings: { slidesToShow: 3 }
					}
				]
			})
    
    $(Selector.EVENTS_NEWS_CAROUSEL).slick({
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {	slidesToShow: 2, dots: true, arrows: false }
        },
        {
          breakpoint: 480,
          settings: { slidesToShow: 1, dots: true, arrows: false }
        }
      ]
    })
  }

  _initServicesCarousel() {
		if (this.mqTablet.matches) {
			wrapperItems($(Selector.SERVICES_CAROUSEL))

			$(Selector.SERVICES_CAROUSEL)
				.on('init', (slick) => {
					$(Selector.SERVICES_CAROUSEL).fadeIn(1000)
				})
				.slick({
					slidesToShow: 4,
					arrows: true,
					dots: false,
					infinite: false
				})
		}
  }

  _initTabsGalleryCarousel() {
		if (this.mqTablet.matches) {
			wrapperItems($(Selector.GALLERY_CAROUSEL))
		}
			
		$(Selector.GALLERY_CAROUSEL).each((i, el) => {
			let $gallery = $(el)
			$gallery
				.on('init', (slick) => {
					$gallery.fadeIn(1000)
				})
				.slick({
					arrows: true,
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: false,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 2,
								arrows: false,
								dots: true
							}
						},
						{
							breakpoint: 480,
							settings: {
								infinite: true,
								slidesToShow: 1,
								arrows: false,
								dots: true,
								centerMode: true,
								centerPadding: '15px'
							}
						}
					]
				})
			})

		// init popup
		$(Selector.GALLERY_CAROUSEL).each((i, el) => {
			$(el).magnificPopup({
				delegate: 'a',
				type: 'image',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0,1],
					tPrev: 'Anterior',
					tNext: 'Siguiente',
					tCounter: '%curr% de %total%'
				}
			})
		})

		$(Selector.TABS_INLINE_GALLERY).tabbedContent({
      history: false,
      historyOnInit: false,
			links: '.tabsInline__item a',
			onSwitch: (tab, api) => {
				$(tab).find(Selector.GALLERY_CAROUSEL)[0].slick.refresh()
			}
    })
  }

  _addEventListeners() {

	}
}

// Init jQuery
;(function ($) {
	new Layout()
  new Site()
  new tabNavigation()
})(jQuery)
